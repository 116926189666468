@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Shadows+Into+Light&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

ul[role="list"],
ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
picture,
svg,
video,
canvas {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition: none;
  }
}

body,
html {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  scroll-behavior: smooth;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  background-color: #fff9df;
}

.page {
  display: flex;
  justify-content: center;
  margin-top: 5em;
  text-align: center;
  padding: 20px;
  max-height: fit-content;
  flex: 1;
}

.recipes-startpage {
  display: flex;
  justify-content: center;
}

.page-name {
  font-family: "Shadows Into Light", cursive;
  font-weight: 600;
  font-style: normal;
  font-size: x-large;
  display: flex;
  margin-top: 80px;
  justify-content: center; /* Horizontaal centreren */
  height: 10px; /* Stel een specifieke hoogte in */
  margin-bottom: -45px; /* Optioneel: ruimte tussen titel en formulier */
}

.filter-category {
  width: 300px;
  border-radius: 6px;
}

h2 {
  margin-bottom: 0.1em;
}

.instruction-content {
  margin-top: 0.5em;
}

.cursief {
  font-family: "Shadows Into Light", cursive;
  font-weight: 600;
  font-style: normal;
  font-size: 36px;
}

.logo {
  width: 10%;
  display: block;
  margin: 0 auto 20px;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.register-container {
  display: grid;
  max-width: 20vw;
  grid-template-columns: repeat(
    auto-fit,
    minmax(200px, 1fr)
  ); /* Responsive layout */
  gap: 5px; /* Reduced spacing between recipe cards */
  justify-items: center; /* Center cards in the grid */
  padding: 20px; /* Reduced padding around the grid */
  box-sizing: border-box; /* Include padding in the grid's width */
}

.row {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: small;
  width: 25vw;
  margin-bottom: 0.5em;
}

.row label {
  width: 100px;
  margin-top: 4px;
  font-size: small;
  text-align: justify;
}

.row select,
.row input {
  flex: 1;
  font-size: small;
  border-radius: 6px;
  padding-left: 10px;
}

.row button {
  align-items: center;
}

.input-wrapper {
  flex: 1;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

input[type="checkbox"] {
  width: 10px;
  height: 10px;
  background: rgb(255, 255, 255);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  left: 5px;
  top: 5px;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
}

input[type="checkbox"]:checked {
  background: #c43402;
}

form {
  width: auto;
}

.username {
  max-height: 1em;
}

button {
  padding: 7px 10px;
  color: white;
  background-color: #c53400;
  border: none;
  border-radius: 6px;
  font-size: small;
}

.recipe-list-container {
  padding: 20px;
}

.home {
  padding: 20px;
}

.intro-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  max-width: 55vw;
}

.startpage-text {
  font-size: 1.1em;
  line-height: 1.6;
  margin-top: 10px;
  font-family: "Montserrat", sans-serif;
}

.recent-recipes {
  margin-top: 1em;
  padding: 0.3em;
  background-color: rgba(255, 255, 255, 0.705);
  border-radius: 10px;
}

.recent-recipes ul {
  list-style: none;
  padding: 0;
}

.recent-recipes li {
  margin: 10px 0;
}

.recipe-grid {
  display: grid;
  max-width: 70vw;
  grid-template-columns: repeat(
    auto-fit,
    minmax(220px, 1fr)
  ); /* Responsive layout */
  gap: 20px; /* Reduced spacing between recipe cards */
  justify-items: center; /* Center cards in the grid */
  padding: 20px; /* Reduced padding around the grid */
  box-sizing: border-box; /* Include padding in the grid's width */
}

.recipe-card {
  width: 100%; /* Full width within grid cell */
  max-width: 220px; /* Optional: limit max width for large screens */
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.divider-line {
  height: 0px;
  background-color: #e0e0e0;
  width: 100%;
}

.recipe-card:hover {
  transform: scale(1.05);
}

.recipe-card-image {
  height: 150px;
  width: 100%;
  object-fit: cover; /* Ensure image fits nicely */
}

.recipe-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recipe-card-content {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  font-size: 1.2em;
  margin: 0;
}

.recipecard-title {
  flex-grow: 1;
  margin-top: 0.5rem;
  color: #000;
  font-weight: 600;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}

.recipecard-items {
  width: 100%;
  display: flex;
  flex-direction: row; /* Zorgt ervoor dat de kinderen naast elkaar staan */
  justify-content: center; /* Verdeel ruimte gelijkmatig */
  align-items: center; /* Lijn kinderen verticaal uit */
}

.recipe-card a {
  text-decoration: none;
  color: #c43402;
}

.upload-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.recipe-form {
  display: flex;
  flex-direction: column;
  min-height: 70vh;
}

.form-group {
  margin-bottom: 8px;
}

.instructies {
  width: 100%;
  min-height: 29em;
}

.recipe-label {
  margin-right: 8px;
  margin-bottom: 4px;
}

.recipecard-name {
  justify-content: end;
  border: none;
  border-bottom: 3px dashed #cba080;
  outline: none;
  width: 80%;
  font-size: 1em;
  background-color: transparent; /* Maakt de achtergrond transparant */
}

.recipecard-name:focus {
  border-bottom-color: #cba080;
}

.recipecard-type {
  border: none;
  border-bottom: 2px dashed #000;
  outline: none;
  padding: 5px;
  width: 150px;
  font-size: x-large;
  background-color: #f9f9f9;
}

.tag-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.recipe-visibility-container {
  text-align: left;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 11px;
  min-height: 6vh;
}

.photo-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.recipe-photo-container {
  background-color: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 20vh;
  text-align: left;
}

.recipe-photo {
  display: flex;
  flex-direction: column;
}

.photo-name-input {
  border: 1px solid #ccc;
  padding: 5px;
  flex-grow: 1;
  background-color: transparent;
  border-radius: 4px;
}

.photo-preview-container {
  margin-top: 10px;
  display: inline-block;
}

.photo-preview-image {
  width: 100px;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.tag-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

.ingredient-input-row {
  display: flex;
  align-items: center;
  gap: 4px;
}

.add-tag-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 39px; /* Set consistent width */
  height: 40px; /* Set consistent height */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em; /* Font size for the "+" */
}

.delete-tag-button {
  background-color: #c43402;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 44px; /* Set consistent width */
  height: 39px; /* Set consistent height */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em; /* Font size for the "-" */
}

.delete-tag-button:hover {
  background-color: #a52901;
}

.add-tag-button:hover {
  background-color: #0056b3;
}

.shared-container {
  display: block;
  text-align: left;
  justify-content: left;
  gap: 10px;
  margin-top: 10px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 7x;
}

.tag-pill {
  background-color: #1195ec69;
  padding: 0 8px;
  border-radius: 15px;
  display: flex;
  align-items: center;
}

.remove-tag-button {
  border: none;
  background: none;
  color: #ff4d4d;
  font-size: large;
  font-weight: bold;
  cursor: pointer;
}

.remove-tag-button:hover {
  border: none;
  background: none;
  cursor: pointer;
}

.recipe-detail-container {
  width: 100%;
  max-width: 1000px;
  margin-bottom: 1em;
  padding: 20px;
  background-image: url("../IMAGES/schrift.webp");
  background-size: 150%;
  background-position: center;
  background-repeat: repeat-y;
  background-color: rgba(255, 255, 255, 0.8); /* Overlay kleur */
  background-blend-mode: multiply; /* Vermengt de overlay met de afbeelding */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
}

.edit-ingredient-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  background-color: #097cff73;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  border: none;
}

.cookie-container {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  max-width: 80vw;
  margin-bottom: 1em;
  padding: 60px;
  background-color: rgba(255, 255, 255, 0.8); /* Overlay kleur */
  background-blend-mode: multiply; /* Vermengt de overlay met de afbeelding */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(190, 181, 181, 0.1);
  position: relative;
}
.recipe-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.2em;
  font-family: "Montserrat", sans-serif;
  font-size: 2em;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0.2em;
  text-align: left;
  gap: 4px;
}

.recipe-author {
  color: #000000;
}

/* Flexibele lay-out voor de kolommen */
.recipe-content-layout {
  display: flex;
  gap: 12px;
}

.recipe-left-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.recipe-right-column {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.recipe-image-container {
  max-width: 100%;
  border: 4px solid white; /* White border around the image container */
  border-radius: 8px; /* Optional rounded corners */
}

.recipe-image {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.placeholder-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  background-color: #eee;
  color: #999;
  border-radius: 8px;
}

/* Ingrediënten en instructies */
.recipe-ingredients,
.recipe-instructions {
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  min-height: fit-content;
  flex: 2;
}

.recipe-instructions ol {
  margin-left: 2em;
}

.recipe-tags {
  text-align: left;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 11px;
  min-height: fit-content;
}

.recipe-servings {
  text-align: left;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 11px;
  min-height: fit-content;
}

.edit-container-name {
  margin-bottom: 4px;
}

.instruction-line {
  font-size: 1.1em;
  margin-bottom: 8px;
  line-height: 1.6;
}

.edit-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #c43402;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  text-decoration: none;
}

.edit-button:hover {
  background-color: #a32901;
}

.instructions-container {
  white-space: pre-wrap; /* Zorgt ervoor dat nieuwe regels behouden blijven */
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  line-height: 1.6;
}

.ingredient-list {
  width: 100%;
  list-style-type: none;
  padding: 0;
}

.ingredient-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

.ingredient-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #d8d8d873;
  padding: 10px;
  border-radius: 4px;
}

.recipe-filters {
  display: flex;
  background-color: rgb(157, 190, 10);
  color: black;
  height: 42px;
  padding-top: 10px;
  padding-left: 85px;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.footer {
  display: flex;
  font-size: medium;
  align-items: center;
  justify-content: center;
  background-color: #d3caa4;
  background-size: cover;
  background-position: center;
  color: rgb(0, 0, 0);
  height: 25px;
  padding: 0 20px;
  width: 100%;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  position: fixed; /* Maak de footer vast aan de onderkant */
  bottom: 0; /* Plaats de footer aan de onderkant van het scherm */
  left: 0; /* Zorg ervoor dat het aan de linkerzijde begint */
  z-index: 100; /* Voorkom dat de footer door andere elementen overlapt wordt */
}

.quill {
  margin-bottom: 1em;
}

.ql-toolbar {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  background-color: #dfd3b5;
}

.ql-editor {
  min-height: 64vh;
  max-height: fit-content;
  font-size: medium;
  font-family: "Montserrat", sans-serif;
}

.ql-container {
  height: 95% !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.file-upload-container {
  display: flex;
  text-align: left;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 11px;
  min-height: fit-content;
}

.material-icons {
  display: flex;
  font-size: medium;
  border-radius: 50%;
}

.file-upload-button .material-icons {
  padding: 7px 10px;
  color: white;
  background-color: #c43402;
  border: none;
  border-radius: 6px;
  font-size: medium;
  margin-right: 0.5em;
}

.file-name {
  display: inline-block;
  margin-left: 10px;
  font-size: 0.9em;
  color: #000000;
  font-size: large;
}

.not-found-container {
  text-align: center;
  padding: 40px;
}

.not-found-container h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.title-and-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recipe-title-text {
  font-size: 1.5rem;
  font-weight: bold;
}

.button-container {
  display: flex;
  gap: 10px;
}

.back-button,
.share-recipe-button {
  background-color: #c43402;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
}

.back-button:hover,
.share-recipe-button:hover {
  background-color: #a82e02;
}

.back-home-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  color: white;
  background-color: #c43402;
  border-radius: 5px;
  text-decoration: none;
}

.back-home-button:hover {
  background-color: #a82e02;
}

.recipe-actions {
  display: flex;
  gap: 2px; /* Space between buttons */
}

button:hover {
  background-color: #a52901; /* Darker shade for hover */
}

.no-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  /* Add other custom styles as needed */
}

.no-button:hover {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1em;
}

.share-toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1em;
  margin-top: 0.5em;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.share-switch {
  position: relative;
  width: 50px;
  height: 25px;
  color: #a02c02;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cccccc;
  transition: 0.4s;
  border-radius: 25px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3.5px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #a52901;
}

input:checked + .slider:before {
  transform: translateX(24px);
}

.password-reset-container {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.reset-password-form {
  display: grid;
  grid-template-columns: 1fr 2fr; /* Kolommen voor labels en inputs */
  gap: 10px; /* Afstand tussen labels en inputs */
  max-width: 400px; /* Max breedte van het formulier */
  margin: 0 auto; /* Centreer het formulier horizontaal */
  align-items: center;
}

.reset-password-form label {
  text-align: right; /* Labels rechts uitlijnen */
  padding-right: 10px; /* Ruimte tussen label en input */
  font-weight: 400;
}

.reset-password-form input {
  width: 100%; /* Inputs vullen hun kolom */
  padding: 8px; /* Binnenruimte */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-button-row {
  grid-column: span 2; /* Laat de knop beide kolommen innemen */
  display: flex;
  justify-content: center;
  margin-top: 15px;
  gap: 10px;
}

.form-button-row button:hover {
  background-color: #a02c02;
}

.swal2-input {
  background-color: #ffffff;
  margin-top: 0.2em;
}

.login-status {
  margin-top: 1em;
  color: #00743e;
}

.pill-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.shared-pill-container {
  display: flex;
  margin-top: 10px;
}

.share-header {
  text-align: left;
  margin-top: -20px;
  margin-bottom: -10px;
}

.share-email-textarea {
  margin-top: 0.3em;
  width: 100%;
  min-height: 80px;
  resize: none;
  padding: 0.3em;
  border-radius: 8px;
}

.shared-email-pill {
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  padding-left: 10px;
  border-radius: 15px;
  display: inline-table;
  align-items: center;
  font-size: smaller;
  font-weight: 600;
  margin-bottom: 0.3em;
}

.shared-email-pill:hover {
  border: 1px solid black;
}

.custom-swal-html {
  border-radius: 1em;
}

.custom-swal-button {
  padding: 7px 10px;
  color: white;
  background-color: #c53400;
  border: none;
  border-radius: 6px;
  font-size: small;
  margin-top: -0.9em;
  margin-bottom: -0.3em;
}

.remove-pill-button {
  margin-left: 5px;
  background: transparent;
  border: none;
  color: red;
  cursor: pointer;
}

.update-button {
  margin-top: 10px;
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.update-button:hover {
  background-color: #0056b3;
}

#shared-users-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remove-user-button {
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.card-icons {
  display: flex;
  gap: 10px; /* Ruimte tussen de iconen */
}

.owner-icon .material-icons {
  font-size: 1.5em;
  color: #004fc5; /* Groen voor eigenaarschap */
}

.public-icon .material-icons {
  font-size: 1.5em;
  color: #c53400; /* Blauw voor openbaar */
}

.shared-icon .material-icons {
  font-size: 1.5em;
  color: #00c50a; /* Oranje voor gedeeld */
}

@media (max-width: 820px) {
  .page {
    margin-top: 7.5em;
  }
}

@media (max-width: 768px) {
  .page {
    margin-top: 7.5em;
  }

  .page-name {
    margin-top: 4em;
    margin-bottom: -3em;
  }

  .intro-text {
    padding: 15px;
    max-width: 100%; /* Zorg ervoor dat de tekst de volledige breedte benut */
  }

  .startpage-text {
    font-size: 14px; /* Pas de tekstgrootte aan voor betere leesbaarheid */
  }

  .recipe-actions {
    justify-content: left;
    margin-top: 0.1em;
  }

  .title-and-buttons {
    display: block;
    align-items: left;
  }
  .action-button {
    width: 100px;
  }

  .recipe-grid {
    max-width: 100vw;
    grid-template-columns: repeat(
      auto-fit,
      minmax(200px, 1fr)
    ); /* Smaller columns for mobile */
  }

  .recipe-card {
    max-width: 90%;
  }

  .recipecard-name {
    width: 100%;
  }

  .recipe-content-layout {
    width: 100%;
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 20px;
  }

  .center {
    display: flex;
    flex-direction: row;
    align-content: space-between;
  }
  .recipe-title {
    display: flex;
    flex-wrap: wrap; /* Zorgt ervoor dat tekst kan omlopen */
    font-size: x-large;
    line-height: 1.4; /* Voor betere leesbaarheid */
    word-wrap: break-word; /* Breekt lange woorden indien nodig */
    max-width: 100%; /* Zorgt ervoor dat het binnen de container blijft */
  }

  .recipecard-name {
    display: flex;
    flex-wrap: wrap; /* Zorgt ervoor dat tekst kan omlopen */
    font-size: x-large;
    line-height: 1.4; /* Voor betere leesbaarheid */
    word-wrap: break-word; /* Breekt lange woorden indien nodig */
    max-width: 100%; /* Zorgt ervoor dat het binnen de container blijft */
  }

  .footer {
    text-align: center;
    font-size: 0.5em;
  }
  .tag-input {
    max-width: 100%;
  }
}
