/* MENU */

.menu {
  display: flex;
  justify-content: center; /* Centreer alle elementen horizontaal */
  align-items: center;
  background-color: #c43402;
  background-image: url("../IMAGES/menubar.webp");
  background-size: cover;
  background-position: center;
  color: white;
  height: 65px;
  padding: 0 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 900;
  box-shadow: 0px 8px 22px #d3b070b9;
  gap: 6px; /* Ruimte tussen de items in het menu */
}

.left-section {
  display: flex;
  align-items: center;
}

.right-section {
  display: flex;
  align-items: center;
  margin-left: 10px;
  min-width: auto;
}

.right-section a {
  text-decoration: none;
}

.logo-menu {
  display: flex;
  align-items: center;
}

.logo-menu img {
  width: 35px;
  height: auto;
}

.site-name {
  font-size: 30px;
  margin-left: 10px;
  cursor: pointer; /* Zorgt voor een handcursor */
  user-select: none; /* Voorkomt dat de tekst geselecteerd kan worden */
}

.search-container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 30%; /* Zorg dat de container de volledige beschikbare ruimte gebruikt */
}

.search-input-menu {
  flex: 1;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  width: 100%; /* Laat de zoekbalk de volledige breedte binnen de flex-container innemen */
}

.search-button {
  padding: 5px 15px; /* Zorg voor wat ruimte rondom de tekst van de knop */
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #c43402;
  color: white;
}

.menu-buttons-container {
  display: flex;
  flex-direction: row;
  justify-items: center;
  gap: 8px;
}

.menu-buttons button {
  color: white;
  background: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
}

.menu-buttons button:hover {
  text-decoration: underline;
}

/* SweetAlert2 styling */
.custom-swal-title {
  font-family: "Shadows Into Light", cursive;
  font-weight: 600;
  font-style: normal;
  font-size: 36px;
}

.custom-swal-input {
  font-weight: 100;
  font-style: normal;
  font-size: 6px;
}

.custom-swal-html {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

.custom-swal-button {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.custom-swal-login-input {
  width: 90%;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  padding: 0.5em;
  margin-bottom: 0.5em;
}

body.swal2-shown {
  overflow-y: scroll !important;
}

@media (max-width: 768px) {
  .menu {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    height: 8em;
    /* Maintain spacing between elements */
  }

  .search-container {
    width: 50%;
  }

  .menu-buttons-container {
    display: flex;
    flex-direction: row;
    justify-items: center;
    gap: 10px;
  }

  .custom-swal-login-input {
    width: 100%;
  }
}

@media (max-width: 820px) {
  .menu {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    height: 8em;
    /* Maintain spacing between elements */
  }

  .search-container {
    width: 50%;
  }

  .menu-buttons-container {
    display: flex;
    flex-direction: row;
    justify-items: center;
    gap: 10px;
  }
}

@media (max-width: 430px) {
  .menu {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    height: 8em;
    /* Maintain spacing between elements */
  }

  .search-container {
    width: 100%;
  }

  .menu-buttons-container {
    display: flex;
    flex-direction: row;
    justify-items: center;
    gap: 10px;
  }

  .custom-swal-login-input {
    width: 100%;
  }
}
